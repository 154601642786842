import React from 'react'
import * as styles from './CapabilityVideo.module.scss'


const CapabilityVideo = ({url, title, background='light', ...args}) => {
    return(
        <section className={`${styles.capabilityVideo} ${styles[background]}`}>
            <iframe width="100%" height="100%" src={url} 
                frameBorder="0" 
                loading="lazy"
                title={title} 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
                {...args}></iframe>
        </section>
    )
}


export default CapabilityVideo