import React, { Fragment } from 'react'
import * as styles from './MainTextBlock.module.scss'
import OutlineButton from '../Buttons/OutlineButton/OutlineButton'

const MainTextBlock = ({
  children,
  buttonData = null,
  secondaryBtnData = null,
  color = 'navy',
}) => {
  return (
    <Fragment>
      <section className={`${styles.mainContent} ${styles[color]}`}>
        {children}
      </section>
      {buttonData && !secondaryBtnData ? (
        <OutlineButton buttonData={buttonData} type={buttonData.type} />
      ) : (
        ''
      )}
      {secondaryBtnData && buttonData ? (
        <div className="inlineBtnWrap">
          <OutlineButton buttonData={buttonData} type={buttonData.type} />
          <OutlineButton
            buttonData={secondaryBtnData}
            type={secondaryBtnData.type}
          />
        </div>
      ) : (
        ''
      )}
    </Fragment>
  )
}

export default MainTextBlock
