import React, { useState, useEffect, useCallback } from 'react'
import Seo from '../Header/SEO/Seo'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'
import Overlay from '../UI/Overlay/Overlay'
import MobileNavigation from '../Header/Navigation/MobileNavigation/MobileNavigation'
import Alert from './../Alerts/Alert'

const GeneralPage = ({ children, pageData }) => {
  const [navOpen, setNavOpen] = useState(false)

  //set to false to turn off all alerts
  const [alertOn, setAlertOn] = useState(true)
  const [alertOpen, setAlertOpen] = useState(true)

  const openMobileNavHandler = (e) => {
    e.preventDefault()
    setNavOpen((prevState) => !prevState)
  }

  //dismiss alerts and set ttl based on importance level
  const dismissAlertClickHandler = (e) => {
    e.preventDefault()
    const now = new Date()

    //set dismissial to expire to future date, in days
    const ttl = {
      alertImportance_high: 1,
      alertImportance_mid: 7,
      alertImportance_low: 30,
    }
    now.setDate(now.getDate() + ttl[e.target.closest('article').id])

    const alertInfo = {
      isDismissed: true,
      expiry: now.getTime(),
    }

    localStorage.setItem('dismissedAlert', JSON.stringify(alertInfo))
    setAlertOpen((prevState) => !prevState)
  }

  const onWindowsClickHandler = useCallback((e) => {
    // e.preventDefault()
    if (window.innerWidth < 1020) {
      if (
        e.target.id !== 'mobileNav' &&
        e.target.id !== 'mobileNav__toggle' &&
        !document.getElementById('mobileNav').contains(e.target)
      ) {
        setNavOpen((prevState) => !prevState)
      }
    } else {
      setNavOpen(false)
    }
  }, [])

  //Check if dismissed alert TTL has passed
  useEffect(() => {
    const dismissedAlertStr = localStorage.getItem('dismissedAlert')
    if (!dismissedAlertStr) {
      setAlertOn(true)
      return
    }
    const dismissedAlert = JSON.parse(dismissedAlertStr)
    const now = new Date()

    if (now.getTime() > dismissedAlert.expiry) {
      localStorage.removeItem('dismissedAlert')
      setAlertOn(true)
      return
    }
    setAlertOn(false)
  }, [])

  //if mobile nav is open, prevent scrolling
  useEffect(() => {
    if (navOpen) {
      document.querySelector('body').classList.add('fixed')
    } else {
      document.querySelector('body').classList.remove('fixed')
    }
  }, [navOpen])

  //if mobile nav is open, allow clicking outside to close mobile nav
  useEffect(() => {
    if (navOpen) {
      window.addEventListener('click', onWindowsClickHandler)
    }
    return () => {
      window.removeEventListener('click', onWindowsClickHandler)
    }
  }, [navOpen, onWindowsClickHandler])

  return (
    <article id="mainContent" className={navOpen ? 'active_nav' : ''}>
      <Seo title={`${pageData.title1} ${pageData.title2}`} />
      {/* {alertOn ? (
        <Alert
          alertData={{ type: 'covid', importanceLevel: 'mid' }}
          clickHandler={dismissAlertClickHandler}
          activeClass={alertOpen ? 'active_alert' : ''}
        />
      ) : (
        ''
      )} */}
      <Header
        pageData={pageData}
        pageType={pageData.pageType}
        mobileNavHandler={openMobileNavHandler}
        activeMobileNav={navOpen}
      />
      <main>{children}</main>
      <Footer />
      <Overlay />
      <MobileNavigation activeClass={navOpen ? 'active_nav' : ''} />
    </article>
  )
}

export default GeneralPage
