import React from "react"
import * as styles from './Alert.module.scss'

import GeneralCovidAlert from './Covid/GeneralCovidAlert'


const Alert = ({alertData, activeClass, clickHandler}) => {
    //alertData{
        //type - which alert displays 
        //importanceLevel - low, mid, high - higher lvl clears dismissed status sooner
    // }
    //on - if there is an active alert
    //show - displayed or not

    //list of all alert options
    const alertTypes = {
        'covid': <GeneralCovidAlert clickHandler={clickHandler} />
    }
    
    return(
        <article id={`alertImportance_${alertData.importanceLevel}`} className={`${styles.alertContainer} ${styles[activeClass]}`}>
            {alertTypes[alertData.type]}
        </article>
    )
}

export default React.memo(Alert)