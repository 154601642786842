import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import AngledContainer from '../components/UI/Containers/AngledContainer'
import CapabilityVideo from '../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ThreeBoxRow from '../components/UI/Images/ThreeBoxRow/ThreeBoxRow'
import ServiceIcon from './../images/icons/capabilities/AdvancedEngineering.inline.svg'

const AdvancedEngineeringPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Advanced',
    title2: 'Engineering',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Engineering from <span>Start to Finish</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                Our professionals skillfully serve their customers with
                comprehensive engineering services and essential interrelated
                technical support services. Our teams manage the overall
                integration of all requirements in a manner that optimizes total
                system performance, quality, cost-effectiveness, and
                supportability.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityVideo
            url="https://www.youtube.com/embed/Nh9PNwZkbs4?yt:cc=on"
            title="Precise Advanced Engineering Informational Video"
          />
        </SectionContainer>

        {/* Why Us */}
        <div id="detailsDisplayServices_Wrap">
          <AngledContainer
            id="detailsDisplayServices"
            color="navy"
            type="fullWidth"
            svgColor="grayToNavy"
          >
            <SectionHeader
              color="white"
              alignment="center"
              size="med"
              trim="true"
            >
              Providing <span>Expertise In</span>
            </SectionHeader>
          </AngledContainer>
          <ThreeBoxRow
            overlaying={true}
            boxContent={{
              first: {
                img: 'carbon:3d-print-mesh',
                text: 'Model Based Systems Engineering',
              },
              second: {
                img: 'clarity:design-line',
                text: 'Systems Engineering & Architecture Design ',
              },
              third: {
                img: 'carbon:block-storage',
                text: 'Digital Engineering',
              },
            }}
          />
        </div>
        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Advanced Engineering"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default AdvancedEngineeringPage
