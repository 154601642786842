import React from "react"
import * as styles from './ThreeBoxRow.module.scss'
import { Icon } from '@iconify/react';
 

const ThreeBoxRow = ({imgIconType=true, boxContent, overlaying=false }) => {
    //boxContent: obj -> first, second, third -> img, text
    return(  
        <article className={`${styles.boxRow} ${overlaying ? `${styles.overlayingBoxes}` : ''}`}>
           <section className={`${styles.box}`}>
                <div className={`${styles.box_imgWrap}`}>
                    { imgIconType ? 
                        <Icon icon={boxContent.first.img} inline={true} width={80}/> :
                        <img src={boxContent.first.img} alt='Talents icon' width='81' height='81'/>
                    }
                </div>
                <p className={`${styles.box_text}`}>
                    {boxContent.first.text}
                </p>
           </section>
           <section className={`${styles.box}`}>
                <div className={`${styles.box_imgWrap}`}>
                    { imgIconType ? 
                        <Icon icon={boxContent.second.img} inline={true} width={80}/> :
                        <img src={boxContent.second.img} alt='Comminications icon' width='81' height='81'/>
                    }
                </div>
                <p className={`${styles.box_text}`}>
                    {boxContent.second.text}
                </p>
           </section> 
           <section className={`${styles.box}`}>
                <div className={`${styles.box_imgWrap}`}>
                    { imgIconType ?
                        <Icon icon={boxContent.third.img} inline={true} width={80}/> :
                        <img src={boxContent.third.img} alt='Target icon' width='81' height='81'/>
                    }
                </div>
                <p className={`${styles.box_text}`}>
                    {boxContent.third.text}
                </p>
           </section>
        </article>
    )
}

export default ThreeBoxRow